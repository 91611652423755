import { useContext, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import { ModalContext } from '../../../contexts/ModalContext';
import { MODAL_MANAGE_TRIAL } from '../constants';
import { useTrialV2Eligibility } from '../../../domains/trials/api/useTrialV2Eligibility';
import { ProductCatalogItem } from '../../../typings';
import { getLatestTrialBundle } from '../../../domains/trials/utils/getLatestTrialBundle';
import { TrialState } from '../../../domains/trials/models/TrialState';
import styles from './TrialManageModal.module.css';

export type TrialManageModalSucessContext =
  | 'extend_trial'
  | 'cancel_trial'
  | 'add_trial';

const TrialManageModal = () => {
  const { isModalOpen, closeModal, openModalProps } =
    useContext(ModalContext) || {};
  const isOpen = isModalOpen?.(MODAL_MANAGE_TRIAL);
  const { modalProps, onSuccess } =
    (openModalProps?.[MODAL_MANAGE_TRIAL] as {
      modalProps: Record<string, string>;
      onSuccess: (context?: TrialManageModalSucessContext) => void;
    }) || {};

  const { isLoading, isEligible: isTrialV2Eligible } = useTrialV2Eligibility({
    site_id: Number(modalProps?.siteId),
    account_id: Number(modalProps?.accountId),
  });

  const handleCloseModal = useCallback(() => {
    closeModal?.(MODAL_MANAGE_TRIAL);
  }, [closeModal]);

  const onModalStartClick = () => {
    onSuccess();
  };

  const onModalExtendClick = () => {
    onSuccess('extend_trial');
  };

  const onModalCancelClick = () => {
    onSuccess('cancel_trial');
  };

  const getActiveTrialOptions = () => {
    return [
      {
        label: 'You can extend the trial',
        disabled: Boolean(modalProps.trialExtensionDisabled),
        btnLabel: 'Extend Trial',
        action: onModalExtendClick,
      },
      {
        label: 'Or cancel the trial',
        disabled: Boolean(modalProps.trialCancellationDisabled),
        btnLabel: 'Cancel Trial',
        action: onModalCancelClick,
      },
    ];
  };

  const trialStates = (modalProps?.trialStates || []) as Array<TrialState>;

  const latestTrialStates = (getLatestTrialBundle(trialStates) || []).filter(
    (trial) => trial.status === 'active'
  );

  const activeTrialData = useMemo(() => {
    return latestTrialStates.map((trial) => {
      const productsCatalog = (modalProps?.productsCatalog ||
        []) as Array<ProductCatalogItem>;
      const product = productsCatalog.find(
        (product) => product.sku === trial.sku
      );

      return {
        product: product?.productName,
        plan: product?.planName,
        allowance: modalProps.isCSLite
          ? product?.allowance?.quotas?.monthlyResponses ??
            `${product?.allowance?.quotas?.analyticsSessionQuota} (${product?.allowance?.quotas?.recordingSessionCount})`
          : product?.allowance?.monthlyResponses ??
            product?.allowance?.dailyCcSessions,
      };
    });
  }, [latestTrialStates, modalProps?.productsCatalog]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen ?? false}
      onClose={handleCloseModal}
    >
      <DialogTitle>Manage Trial v2</DialogTitle>
      <DialogContent>
        {isLoading && (
          <div>
            <p>Loading...</p>
          </div>
        )}
        {!isLoading && isTrialV2Eligible === false && (
          <div>
            <p>{`This ${
              modalProps.isCSLite ? 'account' : 'site'
            } is not eligible for a Trial v2`}</p>
          </div>
        )}
        {!isLoading && isTrialV2Eligible && !modalProps?.hasActiveTrialV2 && (
          <div>
            <p>Start a trial</p>
            <Button
              onClick={onModalStartClick}
              color="primary"
              variant="contained"
            >
              {'Start a trial'}
            </Button>
          </div>
        )}
        {!isLoading && modalProps?.hasActiveTrialV2 && (
          <div>
            <p>
              {`There's an ongoing trial on ${
                modalProps.isCSLite ? 'account' : 'site'
              } ${
                modalProps?.name
                  ? modalProps.name
                  : `ID: ${
                      modalProps.isCSLite
                        ? modalProps?.accountId
                        : modalProps?.siteId
                    }`
              }`}
            </p>
            <div className={styles.trialData}>
              <ul className={styles.trialDataList}>
                {activeTrialData.map((data) => {
                  return (
                    <li key={data.product} className={styles.trialDataListItem}>
                      {`${data.product} ${data.plan} ${data.allowance}`}
                    </li>
                  );
                })}
              </ul>
            </div>
            {getActiveTrialOptions().map((option) => {
              return (
                <div>
                  <p>{option.label}</p>
                  <Button
                    onClick={option.action}
                    color="primary"
                    variant="contained"
                    disabled={option.disabled}
                  >
                    {option.btnLabel}
                  </Button>
                </div>
              );
            })}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrialManageModal;
