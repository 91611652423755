import { useContext, useEffect, useState } from 'react';
import { groupBy, isEmpty } from 'lodash';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  getProductCatalogFromMagpie,
  previewPrice,
  getAmendmentPayload,
  getReactivationPayload,
} from '../../../utils/api';
import { LookupContext } from '../../../contexts/LookupContext';
import { useToasts } from 'react-toast-notifications';
import { Cost } from '../SubscriptionCreationModal/Cost';
import { Subscriptions } from '../SubscriptionCreationModal/Subscriptions';
import { camelKeys } from 'js-convert-case';
import { ASK, MODAL_PREVIEW_COSTS, OBSERVE } from '../constants';
import {
  Allowance,
  Magpie,
  PlanName,
  ProductCatalogItem,
  ProductName,
  SKU,
} from '../../../typings';
import {
  createEntity,
  getSalesDeductionPercentage,
  isBasicAllowance,
} from '../../../containers/Lookup/billingV3.functions';
import { SubscriptionInfo } from './SubscriptionInfo';
import { getBasicPlan } from '../../../utils/getFreePlans';
import { getProductCatalogVersion } from '../../../domains/accounts/utils/getProductCatalogVersion';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import moment, { Moment } from 'moment';
import type { AdmModalProps } from '../AdmModalRoot';

interface FormAllowance extends ProductCatalogItem {
  site: string;
}

interface FormSubscription {
  id: string;
  organization: string;
  site: string;
  allowances: FormAllowance[];
}

export const PreviewCostsModal = ({ onCloseModal }: AdmModalProps<{}>) => {
  const context = useContext(LookupContext);
  const magpie = context?.magpie
    ? (camelKeys(context.magpie, {
        recursive: true,
        recursiveInArray: true,
      }) as Magpie)
    : undefined;

  const { currency, id: accountId } = context?.account;

  const { addToast } = useToasts();
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  const [selectedPreviewDate, setSelectedPreviewDate] = useState<Moment | null>(
    moment()
  );
  useEffect(() => {
    if (magpie?.subscription.currentPeriodEndDate) {
      setSelectedPreviewDate(moment(magpie.subscription.currentPeriodEndDate));
    }
  }, [magpie?.subscription.currentPeriodEndDate]);

  const [productsCatalog, setProductCatalog] = useState<ProductCatalogItem[]>(
    []
  );
  const [formData, setFormData] = useState<{
    subscriptions: FormSubscription[];
    costs: {
      payNow: {
        subTotal: number;
        taxPercentage: number;
        total: number;
      };
      renewWith: { subTotal: number; taxPercentage: number; total: number };
    };
  }>({
    subscriptions: [],
    costs: {
      payNow: {
        subTotal: 0.0,
        taxPercentage: 0.0,
        total: 0.0,
      },
      renewWith: { subTotal: 0.0, taxPercentage: 0.0, total: 0.0 },
    },
  });

  const enrichedMagpieSubscriptions =
    magpie?.subscription.products
      .filter((subscription) => subscription.sku !== 'compound_discount')
      .map(({ sku, entity: { entityIdentifier: siteId } }) => {
        const productCatalogItem = productsCatalog.find(
          (plan) => plan.sku === sku
        ) as ProductCatalogItem;
        return {
          ...productCatalogItem,
          site: siteId,
        };
      }) ?? [];

  const getDefaultCosts = () => ({
    payNow: {
      subTotal: magpie?.currentPeriodCostSummary.amountWithoutTax ?? 0.0,
      taxPercentage: magpie?.currentPeriodCostSummary.taxRate ?? 0.0,
      total: magpie?.currentPeriodCostSummary.amountWithTax ?? 0.0,
    },
    renewWith: {
      subTotal: magpie?.currentPeriodCostSummary.amountWithoutTax ?? 0.0,
      taxPercentage: magpie?.currentPeriodCostSummary.taxRate ?? 0.0,
      total: magpie?.currentPeriodCostSummary.amountWithTax ?? 0.0,
    },
  });

  const fillMissingBasicPlan = (
    plans: (ProductCatalogItem & { site: string })[],
    site: string,
    productsCatalog: ProductCatalogItem[]
  ) => {
    const askBasicPlan = getBasicPlan(ASK, productsCatalog);
    const observeBasicPlan = getBasicPlan(OBSERVE, productsCatalog);

    if (plans.length === 2) {
      return plans;
    }

    if (observeBasicPlan && plans.some((plan) => plan.productName === ASK)) {
      return [...plans, { ...observeBasicPlan, site }];
    }

    if (askBasicPlan && plans.some((plan) => plan.productName === OBSERVE)) {
      return [...plans, { ...askBasicPlan, site }];
    }

    return plans;
  };

  useEffect(() => {
    if (!magpie || !context.organizations || !productsCatalog.length) {
      return;
    }

    const getOrganizationIdFromSite = (siteId: string) =>
      Object.values(
        context.organizations as Record<string, { id: number; sites: number[] }>
      )
        .find(({ sites }) => sites.includes(Number(siteId)))
        ?.id.toString() ?? '';

    const convertMagpieSubscriptionToFormSubscription =
      (): FormSubscription[] => {
        const groupedSubscriptionsBySite = groupBy(
          enrichedMagpieSubscriptions,
          'site'
        );

        return Object.entries(groupedSubscriptionsBySite).map(
          ([siteId, allowances]) => {
            const orgId = getOrganizationIdFromSite(siteId);
            if (allowances.length !== 2) {
              allowances = fillMissingBasicPlan(
                allowances,
                siteId,
                productsCatalog
              );
            }
            return {
              id: `subscription-${orgId}-${siteId}`,
              organization: orgId,
              site: siteId,
              allowances,
            };
          }
        );
      };

    const data = {
      costs: getDefaultCosts(),
      subscriptions: convertMagpieSubscriptionToFormSubscription(),
    };
    setFormData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.magpie, context.organizations, productsCatalog]);

  // TODO: Create a custom hook for getting product catalog
  useEffect(() => {
    if (!accountId || !currency || !magpie) return;

    const getPlans = async () => {
      try {
        const plansRes = await getProductCatalogFromMagpie(
          magpie.subscription.billingPeriod.toLowerCase(),
          currency,
          getProductCatalogVersion(context?.account)
        );
        const plansData: {
          product_name: ProductName;
          plans: {
            plan_name: PlanName;
            allowances: Allowance[];
          }[];
        }[] = await plansRes.json();

        const productsCatalog = plansData
          .map((product) =>
            product.plans.map((plan) =>
              plan.allowances.map((allowance) => ({
                sku: allowance.sku,
                allowance:
                  (camelKeys(allowance, {
                    recursive: true,
                    recursiveInArray: true,
                  }) as Allowance) ?? [],
                planName: plan.plan_name,
                productName: product.product_name,
              }))
            )
          )
          .flat(2);
        setProductCatalog(productsCatalog);
      } catch (e) {
        console.log(e);
      }
    };
    getPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context?.magpie, currency, accountId]);

  const hasSelectedPlanChanged = (
    sku: SKU,
    productName: ProductName,
    siteId: string
  ) => {
    const siteProducts = enrichedMagpieSubscriptions.filter(
      (product) => product.site === siteId
    );
    const siteProductsWithBasic = fillMissingBasicPlan(
      siteProducts,
      siteId,
      siteProducts
    );
    return siteProductsWithBasic
      .filter((product) => product.productName === productName)
      .every((product) => product.sku !== sku);
  };

  const preparePayload = () => {
    const changedPlans = formData.subscriptions.flatMap(
      ({ site, allowances }) =>
        allowances.filter(({ sku, productName }) =>
          hasSelectedPlanChanged(sku, productName, site)
        )
    );

    const activePlans =
      enrichedMagpieSubscriptions.filter((sitePlan) =>
        changedPlans.some((plan) => plan.site === sitePlan.site)
      ) ?? [];

    const productsToAdd = changedPlans
      .filter(
        (selectedPlan) =>
          !isBasicAllowance(selectedPlan.sku, productsCatalog) &&
          activePlans
            .filter((activePlan) => selectedPlan.site === activePlan.site)
            .every((activePlan) => selectedPlan.sku !== activePlan.sku)
      )
      .map(({ sku, site }) => ({
        sku,
        entity: createEntity(site, context.sites[site].name),
      }));

    const productsToRemove = activePlans
      .filter((activePlan) =>
        changedPlans.some(
          (selectedPlan) =>
            selectedPlan.site === activePlan.site &&
            selectedPlan.productName === activePlan.productName &&
            selectedPlan.sku !== activePlan.sku
        )
      )
      .map(({ sku, site }) => ({
        entity: createEntity(site, context.sites[site].name),
        sku,
      }));

    return { productsToAdd, productsToRemove };
  };

  const handleError = (error: Error) => {
    const errorMessage = error?.message ?? error?.name;
    addToast(errorMessage, {
      appearance: 'error',
      autoDismiss: true,
    });
    console.error('Order', JSON.stringify(error));
  };

  const hasDuplicatedSite = () => {
    const addedSites = formData.subscriptions.map(({ site }) => site);
    return new Set(addedSites).size !== addedSites.length;
  };
  const previewCosts = async () => {
    const isCanceledSubscription = magpie?.subscription.status === 'CANCELLED';

    if (hasDuplicatedSite()) {
      handleError(
        new Error('Please remove the duplicated site/sites to continue')
      );
      return;
    }

    const { productsToAdd, productsToRemove } = preparePayload();

    if (!productsToAdd.length && !productsToRemove.length) {
      setFormData({ ...formData, costs: getDefaultCosts() });
      return;
    }

    const salesDeductionPercentage =
      getSalesDeductionPercentage(magpie?.subscription) ?? 0;

    const amendmentData = {
      productsToAdd,
      productsToRemove,
      salesDeductionPercentage,
    };

    const reactivationData = {
      billingPeriod: magpie?.subscription.billingPeriod,
      products: productsToAdd,
      salesDeductionPercentage,
    };

    let payload = isCanceledSubscription
      ? getReactivationPayload(accountId, reactivationData)
      : getAmendmentPayload(accountId, amendmentData);

    setIsPreviewLoading(true);
    try {
      payload = {
        ...payload,
        order_date: selectedPreviewDate?.format('YYYY-MM-DD'),
      };
      const rawResponse = await previewPrice(payload);
      const response = await rawResponse.json();
      if (!rawResponse.ok) {
        const errorMessage = response?.message ?? rawResponse.status;
        throw new Error(errorMessage);
      }
      const payNow = {
        subTotal: response.pay_now.amount_without_tax,
        taxPercentage: response.pay_now.tax_rate,
        total: response.pay_now.amount_with_tax,
      };

      const renewWith = {
        subTotal: response.renew_with.amount_without_tax,
        taxPercentage: response.renew_with.tax_rate,
        total: response.renew_with.amount_with_tax,
      };

      setFormData({
        ...formData,
        costs: {
          payNow,
          renewWith,
        },
      });
      setIsPreviewLoading(false);
    } catch (error) {
      setIsPreviewLoading(false);
      handleError(error as Error);
    }
  };

  const handleSubscriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    oldAllowance: FormAllowance
  ) => {
    const updatedSubscriptions = [...formData.subscriptions];
    const { id: eventId, value: eventValue } = event.target as {
      id: 'organization' | 'site' | 'plan';
      value: string;
    };
    const isPlanChange = eventId === 'plan';
    const isSiteChange = eventId === 'site';
    const currentSubscription = formData.subscriptions[index];
    let allowances = currentSubscription.allowances;

    if (isPlanChange) {
      allowances = [
        ...currentSubscription.allowances,
        {
          ...(productsCatalog.find(
            (product) => product.sku === eventValue
          ) as ProductCatalogItem),
          site: oldAllowance.site,
        },
      ];

      // Remove the old one
      if (allowances.length === 3) {
        const indexOfAllowanceToRemove = allowances.findIndex(
          (allowance) => allowance.sku === oldAllowance.sku
        );
        allowances.splice(indexOfAllowanceToRemove, 1);
      }
    } else {
      updatedSubscriptions[index][eventId] = eventValue;
    }

    if (isSiteChange) {
      allowances = allowances.map((allowance) => ({
        ...allowance,
        site: eventValue,
      }));
    }

    updatedSubscriptions[index] = {
      ...currentSubscription,
      allowances,
    };

    setFormData({
      ...formData,
      subscriptions: updatedSubscriptions,
    });
  };

  const addNewSubscription = () => {
    const askBasicPlan = getBasicPlan(ASK, productsCatalog);
    const observeBasicPlan = getBasicPlan(OBSERVE, productsCatalog);

    if (askBasicPlan && observeBasicPlan) {
      setFormData({
        ...formData,
        subscriptions: [
          ...formData.subscriptions,
          {
            id: `subscription-${new Date().getTime()}`,
            organization: '',
            site: '',
            allowances: [
              { ...askBasicPlan, site: '' },
              { ...observeBasicPlan, site: '' },
            ],
          },
        ],
      });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open
      onClose={() => {
        context.closeModal(MODAL_PREVIEW_COSTS);
      }}
      disableEnforceFocus
    >
      <DialogTitle>Preview Costs for Account {accountId}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Cost
              subTotal={formData.costs.payNow.subTotal}
              taxPercentage={formData.costs.payNow.taxPercentage}
              total={formData.costs.payNow.total}
              isPreviewLoading={isPreviewLoading}
              currency={currency}
              textAlign={'left'}
              title="Pay Now"
            />
          </Grid>
          <Grid item xs={6}>
            <Cost
              subTotal={formData.costs.renewWith.subTotal}
              taxPercentage={formData.costs.renewWith.taxPercentage}
              total={formData.costs.renewWith.total}
              isPreviewLoading={isPreviewLoading}
              currency={currency}
              textAlign={'left'}
              title="Renew With"
            />
          </Grid>
        </Grid>

        {magpie && <SubscriptionInfo subscription={magpie.subscription} />}

        <Grid>
          <Grid item xs={6} style={{ marginTop: '82px', marginBottom: '10px' }}>
            <h2>Configure Preview</h2>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Preview date"
                value={selectedPreviewDate}
                inputFormat="YYYY-MM-DD"
                minDate={moment()}
                onChange={(newSelectedPreviewDate) =>
                  setSelectedPreviewDate(newSelectedPreviewDate)
                }
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>

        {!isEmpty(formData?.subscriptions) && (
          <Subscriptions
            formData={formData}
            addNewSubscription={addNewSubscription}
            handleSubscriptionChange={handleSubscriptionChange}
            organizations={context.organizations}
            sites={context.sites}
            productsCatalog={productsCatalog}
            showDeleteIcon={false}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            context.closeModal(MODAL_PREVIEW_COSTS);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={previewCosts}
          variant="contained"
          color="primary"
          loading={isPreviewLoading}
        >
          Preview costs
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewCostsModal;
