import * as Cookies from 'js-cookie';
import queryString from 'query-string';
import Mixpanel from './mixpanel';
import { pickBy, identity } from 'lodash';
import { snakeKeys } from 'js-convert-case';
import { createEntity } from '../containers/Lookup/billingV3.functions';

export const qsArgs = (args) => {
  // pickBy removes all falsy values from the object, allowing us to pass null args etc
  const result = queryString.stringify(pickBy(args, identity));
  return result ? `?${result}` : '';
};

// Added since we start using react query
// Eventually it will the only one to be used
export const buildUrl = (url) => `https://${window.location.host}${url}`;

const constructUrl = (endpoint, version, adm) => {
  if (adm) {
    return (
      'https://' + window.location.host + '/api/v' + version + '/adm' + endpoint
    );
  }
  return 'https://' + window.location.host + '/api/v' + version + endpoint;
};

const errorMessage = (statusCode) => {
  let message;
  switch (statusCode) {
    case 403:
      message = '403: Unauthenticated. Please login.';
      break;
    case 404:
      message = '404: Not found.';
      break;
    case 401:
      message = '401: Unauthorized';
      break;
    default:
      message = `Error code: ${statusCode}`;
      break;
  }
  return message;
};

const trackAPICall = (endpoint, method, params, customEventName = null) => {
  let eventName = customEventName;
  if (!eventName) {
    let model = endpoint.split('/')[1];
    if (model.indexOf('?') > -1) model = model.split('?')[0];
    eventName = `${model} request`;
  }

  Mixpanel.track(eventName, {
    endpoint,
    method,
    params,
  });
};

const apiCall = (
  endpoint,
  method,
  version = 2,
  params = {},
  headers = {},
  adm = true,
  customEventName,
  trackingExempt
) => {
  let init = {
      method: method,
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
        ...headers,
      },
    },
    url = constructUrl(endpoint, version, adm);
  if (Object.keys(params).length) {
    init.body = JSON.stringify(params);
  }
  return fetch(url, init).then((response) => {
    let contentType = response.headers.get('content-type', '');

    // if server (AWS) doesn't send contentType in response. Usually on https
    if (!contentType) {
      contentType = '';
    }

    !trackingExempt && trackAPICall(endpoint, method, params, customEventName);

    // Handle the ok and not ok responses if we have JSON
    if (contentType.includes('application/json')) {
      if (response.ok) {
        return Promise.resolve(
          response.status === 204 ? null : response.json()
        );
      } else {
        if (response.status === 401) {
          Cookies.remove('LOGGED-IN');
        }
        return response.json().then((data) => {
          return Promise.reject({
            response,
            message: errorMessage(response.status),
            ...data,
          });
        });
      }
    } else if (contentType.startsWith('text/')) {
      if (response.ok) {
        return Promise.resolve(
          response.status === 204 ? null : response.text()
        );
      } else {
        if (response.status === 401) {
          Cookies.remove('LOGGED-IN');
        }
        return Promise.reject({
          response,
          message: errorMessage(response.status),
        });
      }
    } else {
      if (response.ok) {
        return Promise.resolve(
          response.status === 204 ? null : response.blob()
        );
      } else {
        if (response.status === 401) {
          Cookies.remove('LOGGED-IN');
        }
        return Promise.reject({
          response,
          message: errorMessage(response.status),
        });
      }
    }
  });
};

export const apiGet = (
  endpoint,
  params = {},
  version,
  headers = {},
  adm,
  customEventName,
  trackingExempt
) => {
  return apiCall(
    endpoint + qsArgs(params),
    'GET',
    version,
    {},
    headers,
    adm,
    customEventName,
    trackingExempt
  );
};

export const apiPost = (
  endpoint,
  action,
  params = {},
  version,
  headers = {},
  adm,
  customEventName,
  trackingExempt
) => {
  customEventName = !customEventName ? action : customEventName;
  return apiCall(
    endpoint,
    'POST',
    version,
    { ...params, action },
    {
      ...headers,
      'Content-Type': 'application/json',
    },
    adm,
    customEventName,
    trackingExempt
  );
};

export const apiPut = (
  endpoint,
  params,
  version,
  headers = {},
  adm,
  customEventName,
  trackingExempt
) => {
  return apiCall(
    endpoint,
    'PUT',
    version,
    params,
    {
      ...headers,
      'Content-Type': 'application/json',
    },
    adm,
    customEventName,
    trackingExempt
  );
};

export const apiPatch = (
  endpoint,
  params,
  version,
  headers = {},
  adm,
  customEventName,
  trackingExempt
) => {
  return apiCall(
    endpoint,
    'PATCH',
    version,
    params,
    {
      ...headers,
      'Content-Type': 'application/json',
    },
    adm,
    customEventName,
    trackingExempt
  );
};

export const apiDelete = (
  endpoint,
  action,
  params = {},
  version,
  headers = {},
  adm,
  customEventName,
  trackingExempt
) => {
  return apiCall(
    endpoint,
    'DELETE',
    version,
    { ...params, action },
    {
      ...headers,
      'Content-Type': 'application/json',
    },
    adm,
    customEventName,
    trackingExempt
  );
};

export const getCustomerFromMagpie = (accountId) => {
  return fetch(`/api/v2/adm/magpie/customers/${accountId}`);
};

export const getProductCatalogFromMagpie = (
  billingPeriod,
  currency,
  catalogVariant = 'v3'
) => {
  return fetch(
    `/api/v2/adm/magpie/${catalogVariant}/product_catalog/${billingPeriod}/${currency}`
  );
};

export const magpiePostRequest = (endpoint, payload, abortSignal) => {
  return fetch(`/api/v2/adm/magpie/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
    signal: abortSignal,
  });
};

export const getAdditionalEmailAddresses = (additionalEmailAddresses) => {
  // We need to explicitly send null to magpie to avoid
  // override any existing email addresses
  // see: https://hotjar.atlassian.net/browse/RENG-185
  if (additionalEmailAddresses === null) {
    return null;
  }

  if (!additionalEmailAddresses) {
    return [];
  }
  if (Array.isArray(additionalEmailAddresses)) {
    return additionalEmailAddresses;
  }
  const emails = additionalEmailAddresses.replace(/\s+/g, '').split(',');

  // If a comma is added at the end, remove the last index
  if (emails.at(-1) === '') {
    emails.pop();
  }
  return emails;
};

const getCustomerPayload = (
  hotjarAccountId,
  {
    user: { firstName, lastName, emailAddress },
    companyName,
    taxNumber,
    paymentTerm,
    invoiceNotes,
    purchaseOrderNumber,
    additionalEmailAddresses,
    ...billingAddress
  }
) => ({
  customer: {
    hotjarAccountId,
    companyName,
    taxNumber,
    paymentTerm,
    invoiceNotes,
    purchaseOrderNumber,
    additionalEmailAddresses: getAdditionalEmailAddresses(
      additionalEmailAddresses
    ),
    payment_method: {
      payment_method_type: 'EXTERNAL_PAYMENT_METHOD',
    },
    billingAddress: {
      ...billingAddress,
      firstName,
      lastName,
      emailAddress,
    },
  },
});

export const getCreationPayload = (
  accountId,
  data,
  sites,
  nonChargeableSkus
) => {
  const {
    discountPercentage,
    billingPeriod,
    subscriptions,
    user,
    ...customer
  } = data;

  const productsToAdd = subscriptions.reduce((products, subscription) => {
    const { allowances, site } = subscription;

    products = [
      ...products,
      ...allowances
        .filter((allowance) => !nonChargeableSkus.includes(allowance.sku))
        .map((allowance) => ({
          sku: allowance.sku,
          entity: createEntity(site, sites[site].name),
        })),
    ];

    return products;
  }, []);

  const payload = {
    ...getCustomerPayload(accountId, { ...customer, user }),
    subscription: {
      billingPeriod,
      products: productsToAdd,
      salesDeductionPercentage: Number(discountPercentage),
    },
  };

  return {
    customer_and_subscription_creation_revised_order: snakeKeys(payload, {
      recursive: true,
      recursiveInArray: true,
    }),
  };
};

export const getAmendmentPayload = (
  accountId,
  { productsToAdd, productsToRemove, salesDeductionPercentage }
) => {
  const payload = {
    amend_subscription_and_billing_period_order: {
      hotjarAccountId: accountId,
      productsToAdd: productsToAdd.map(({ entity, sku }) => ({
        sku: sku,
        entity,
      })),
      productsToRemove: productsToRemove.map(({ entity, sku }) => ({
        sku: sku,
        entity,
      })),
      salesDeductionPercentage: salesDeductionPercentage
        ? salesDeductionPercentage
        : 0,
    },
  };

  return snakeKeys(payload, {
    recursive: true,
    recursiveInArray: true,
  });
};

/**
 *
 * @param {Number} accountId
 * @param {{ billingPeriod: ("ANNUALLY" | "MONTHLY" | "THREE MONTHS" | "SIX MONTHS" | undefined), products: Object[], salesDeductionPercentage: Number }} data
 */
export const getReactivationPayload = (
  accountId,
  { billingPeriod, products, salesDeductionPercentage }
) => {
  const payload = {
    reactivate_subscription_order: {
      hotjarAccountId: accountId,
      subscription: {
        billingPeriod,
        products: products.map(({ entity, sku }) => ({
          sku,
          entity,
        })),
      },
      salesDeductionPercentage: salesDeductionPercentage
        ? salesDeductionPercentage
        : 0,
    },
  };

  return snakeKeys(payload, {
    recursive: true,
    recursiveInArray: true,
  });
};

const getUpdateCustomerDetailsPayload = (accountId, formData) => {
  const { customer } = getCustomerPayload(accountId, formData);
  return {
    update_customer_details: snakeKeys(customer, {
      recursive: true,
      recursiveInArray: true,
    }),
  };
};

export const previewPrice = (payload, abortSignal) => {
  return magpiePostRequest('orders/preview/v1', payload, abortSignal);
};

export const createSubscriptionInMagpie = (payload) => {
  return magpiePostRequest('orders', payload);
};

export const amendSubscriptionInMagpie = (accountId, data) => {
  return magpiePostRequest('orders', getAmendmentPayload(accountId, data));
};

/**
 *
 * @param {Number} accountId
 * @param {{ billingPeriod: ("ANNUALLY" | "MONTHLY"), products: Object[], salesDeductionPercentage: Number }} data
 */
export const reactivateSubscriptionInMagpie = (accountId, data) => {
  return magpiePostRequest('orders', getReactivationPayload(accountId, data));
};

export const updateCustomerDetails = (accountId, data) => {
  return magpiePostRequest(
    'orders',
    getUpdateCustomerDetailsPayload(accountId, data)
  );
};

export const getProducts = (accountId) =>
  fetch(`/api/v2/adm/accounts/${accountId}/product_catalog/products`);

export const cancelSubscriptionOrderInMagpie = (hotjar_account_id) => {
  return fetch('/api/v2/adm/magpie/orders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      cancel_subscription_order: { hotjar_account_id },
    }),
  });
};

export const cancelSubscriptionImmediatelyAndRemoveCustomerInMagpie = (
  hotjar_account_id
) => {
  return fetch('/api/v2/adm/magpie/orders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      remove_customer_immediate_cancellation_order: { hotjar_account_id },
    }),
  });
};

export const updateCustomerEmailRecipients = ({
  accountId,
  emailRecipients,
}) => {
  return fetch(`/api/v2/adm/account/${accountId}/email-recipients`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email_recipients: emailRecipients,
    }),
  });
};

export const getCustomerEmailRecipients = async ({ accountId }) => {
  const response = await parseResponseAndCheckForError(
    await fetch(`/api/v2/adm/account/${accountId}/email-recipients`)
  );

  return response.email_recipients;
};

export const updateCustomerCountry = ({ accountId, country, annotation }) => {
  return fetch(`/api/v2/adm/account/${accountId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      action: 'set_country',
      adm_action_annotation: annotation,
      country,
    }),
  });
};

export const parseResponseAndCheckForError = async (rawResponse) => {
  const parsedResponse = await rawResponse.json();
  if (!rawResponse.ok) {
    throw new Error(parsedResponse?.message ?? rawResponse.status);
  }
  return parsedResponse;
};
