import { isNil } from 'lodash';
import containerStyles from '../Lookup.module.css';
import { toUTCDateString, addDays } from '../../../utils/dates';
import { NextBillingDateMismatch } from './next-bill-date-mismatch';

function getMagpieDateString(date: string | undefined): string | undefined {
  if (isNil(date)) {
    return undefined;
  }
  // Adjust Magpie's date by one day, since we're billing a day after the end of the current billing period
  return toUTCDateString(addDays(date, 1));
}

function getInsightsDateString(date: Date | null): string | undefined {
  if (isNil(date)) {
    return undefined;
  }

  return toUTCDateString(date);
}

type AccountNextBillingDateProps = {
  magpieCurrentPeriodEndDate: string | undefined;
  insightsNextBillingDate: Date | null;
};

export function AccountNextBillingDate(
  props: AccountNextBillingDateProps
): JSX.Element {
  const { insightsNextBillingDate, magpieCurrentPeriodEndDate } = props;
  const magpieUtcDate = getMagpieDateString(magpieCurrentPeriodEndDate);
  const insightsUtcDate = getInsightsDateString(insightsNextBillingDate);
  const utcDate = magpieUtcDate ?? insightsUtcDate;

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <dt className={containerStyles.label}>Next Bill:</dt>
      <dd className={containerStyles.value}>
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          {utcDate}
          <NextBillingDateMismatch
            insightsDate={insightsUtcDate}
            magpieDate={magpieUtcDate}
          />
        </div>
      </dd>
    </div>
  );
}
