import { MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { ModalProviderContext } from '../../../../contexts/ModalContext';
import {
  MODAL_ACTIVATE_TRIAL,
  MODAL_BASIC_ALERT_DIALOG,
  MODAL_CANCEL_TRIAL,
  MODAL_EXTEND_TRIAL,
  MODAL_MANAGE_TRIAL,
} from '../../../../components/modals/constants';
import { TrialState } from '../../models/TrialState';
import { getProductCatalogFromMagpie } from '../../../../utils/api';
import { camelKeys } from 'js-convert-case';
import {
  Allowance,
  PlanName,
  ProductCatalogItem,
  ProductName,
} from '../../../../typings';

interface Props {
  isCsLite: boolean;
  show: boolean;
  modalContext: ModalProviderContext;
  modalProps: {
    name: string;
    siteId: number;
    accountId: number;
    hasActiveTrialV2: boolean;
    trialStates: Array<TrialState>;
    isTrialExtensionInProgress: boolean;
    isTrialExtended: boolean;
    productCatalogVersion: string;
    onCloseModalPopover: Function;
  };
}

interface PlansData {
  product_name: ProductName;
  plans: {
    plan_name: PlanName;
    allowances: Allowance[];
  }[];
}

const ManageTrialMenuItem = ({
  show,
  isCsLite,
  modalProps,
  modalContext,
}: Props) => {
  const [productsCatalogue3Y, setProductsCatalogue3Y] =
    useState<ProductCatalogItem[]>();

  const getPlans = async () => {
    try {
      const plansRes3Y = await getProductCatalogFromMagpie(
        isCsLite ? 'annually' : 'three_years',
        'eur',
        modalProps.productCatalogVersion
      );
      const plansData3Y: PlansData[] = await plansRes3Y.json();

      const productsCatalog3Y = plansData3Y
        .map((product) =>
          product.plans.map((plan) =>
            plan.allowances.map((allowance) => {
              return {
                sku: allowance.sku,
                allowance:
                  camelKeys(allowance, {
                    recursive: true,
                    recursiveInArray: true,
                  }) ?? [],
                planName: plan.plan_name,
                productName: product.product_name,
                ...(isCsLite
                  ? {
                      isVoC: product.product_name === 'VoC',
                      isDXA: product.product_name === 'DXA',
                    }
                  : {
                      isAsk: product.product_name === 'Ask',
                      isObserve: product.product_name === 'Observe',
                    }),
              } as ProductCatalogItem;
            })
          )
        )
        .flat(2);

      setProductsCatalogue3Y(productsCatalog3Y);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handleOpenConfirmTrialExtensionModal = () => {
    const trialExtensionModalContent = (
      <span>
        {`A request was sent to extend the trial for ${
          isCsLite ? 'account' : 'site'
        } ${
          modalProps?.name
            ? modalProps.name
            : `ID: ${isCsLite ? modalProps?.accountId : modalProps?.siteId}`
        }.`}
        <p> This can take a few seconds.</p>
        <p> Please refresh ADM to check trial state.</p>
      </span>
    );

    modalContext.openModal(MODAL_BASIC_ALERT_DIALOG, {
      title: 'Trial Extended',
      modalWarning: trialExtensionModalContent,
    });
  };

  const handleTrialExtendSuccess = () => {
    modalContext.closeModal(MODAL_EXTEND_TRIAL);
    modalProps.onCloseModalPopover();
    handleOpenConfirmTrialExtensionModal();
  };

  const handleOpenConfirmTrialExtendModal = () => {
    const activeTrials = modalProps.trialStates.filter(
      (trial) => trial.status === 'active'
    );
    const firstActiveTrial =
      activeTrials.length > 0 ? activeTrials[0] : undefined;

    if (
      !firstActiveTrial ||
      (!isCsLite && !modalProps.siteId) ||
      (isCsLite && !modalProps.accountId)
    ) {
      return;
    }

    const bundleId = firstActiveTrial
      ? firstActiveTrial.meta?.bundle_id
      : undefined;
    const bundleName = firstActiveTrial
      ? firstActiveTrial.meta?.bundle_name
      : undefined;

    const isSingleProductTrial = !bundleId && !bundleName;

    modalContext.openModal(MODAL_EXTEND_TRIAL, {
      onSuccess: handleTrialExtendSuccess,
      modalProps: {
        name: modalProps.name,
        siteId: modalProps.siteId,
        bundleId,
        trialId: firstActiveTrial.id ?? undefined,
        accountId: modalProps.accountId,
        isSingleProductTrial,
        isCSLite: isCsLite,
      },
    });
  };

  const handleOpenConfirmTrialCancellationModal = () => {
    const trialCancelModalContent = (
      <span>
        {`A request was sent to cancel the trial for ${
          isCsLite ? 'account' : 'site'
        } ${
          modalProps?.name
            ? modalProps.name
            : `ID: ${isCsLite ? modalProps?.accountId : modalProps?.siteId}`
        }.`}
        <p> This can take a few seconds.</p>
        <p> Please refresh ADM to check trial state.</p>
      </span>
    );

    modalContext.openModal(MODAL_BASIC_ALERT_DIALOG, {
      title: 'Trial Cancel',
      modalWarning: trialCancelModalContent,
    });
  };

  const handleTrialCancelSuccess = () => {
    modalContext.closeModal(MODAL_CANCEL_TRIAL);
    modalProps.onCloseModalPopover();
    handleOpenConfirmTrialCancellationModal();
  };

  const handleOpenConfirmTrialCancelModal = () => {
    const activeTrials = modalProps.trialStates.filter(
      (trial) => trial.status !== 'ended'
    );
    const firstActiveTrial =
      activeTrials.length > 0 ? activeTrials[0] : undefined;

    if (
      !firstActiveTrial ||
      (!isCsLite && !modalProps.siteId) ||
      (isCsLite && !modalProps.accountId)
    ) {
      return;
    }

    const bundleId = firstActiveTrial
      ? firstActiveTrial.meta?.bundle_id
      : undefined;
    const bundleName = firstActiveTrial
      ? firstActiveTrial.meta?.bundle_name
      : undefined;

    const isSingleProductTrial = !bundleId && !bundleName;

    modalContext.openModal(MODAL_CANCEL_TRIAL, {
      onSuccess: handleTrialCancelSuccess,
      modalProps: {
        name: modalProps.name,
        siteId: modalProps.siteId,
        bundleId,
        trialId: firstActiveTrial.id ?? undefined,
        accountId: modalProps.accountId,
        isSingleProductTrial,
        isCSLite: isCsLite,
      },
    });
  };

  const handleOpenConfirmTrialInitiatedModal = () => {
    const trialInitiatedModalContent = (
      <span>
        {`A request was sent to initiate the trial for ${
          isCsLite ? 'account' : 'site'
        } ${
          modalProps?.name
            ? modalProps.name
            : `ID: ${isCsLite ? modalProps?.accountId : modalProps?.siteId}`
        }.`}
        <p> This can take a few seconds.</p>
        <p> Please refresh ADM to check trial state.</p>
      </span>
    );

    modalContext.openModal(MODAL_BASIC_ALERT_DIALOG, {
      title: 'Trial Initiated',
      modalWarning: trialInitiatedModalContent,
    });
  };

  const handleTrialSuccess = () => {
    modalContext.closeModal(MODAL_ACTIVATE_TRIAL);
    modalProps.onCloseModalPopover();
    handleOpenConfirmTrialInitiatedModal();
  };

  const handleOpenConfirmTrialModal = () => {
    modalContext.openModal(MODAL_ACTIVATE_TRIAL, {
      onSuccess: handleTrialSuccess,
      modalProps: {
        name: modalProps.name,
        siteId: modalProps.siteId,
        accountId: modalProps.accountId,
        productsCatalog: productsCatalogue3Y,
        isCSLite: isCsLite,
      },
    });
  };

  const handleTrialManageSuccess = (context: string) => {
    modalContext.closeModal(MODAL_MANAGE_TRIAL);
    modalProps.onCloseModalPopover();

    if (context === 'extend_trial') {
      handleOpenConfirmTrialExtendModal();
      return;
    }

    if (context === 'cancel_trial') {
      handleOpenConfirmTrialCancelModal();
      return;
    }

    handleOpenConfirmTrialModal();
  };

  const handleOpenManageTrialModal = () => {
    modalContext.openModal(MODAL_MANAGE_TRIAL, {
      onSuccess: handleTrialManageSuccess,
      modalProps: {
        name: modalProps.name,
        siteId: modalProps.siteId,
        accountId: modalProps.accountId,
        productsCatalog: productsCatalogue3Y,
        hasActiveTrialV2: modalProps.hasActiveTrialV2,
        trialStates: modalProps.trialStates,
        trialExtensionDisabled:
          !modalProps.hasActiveTrialV2 ||
          modalProps.isTrialExtensionInProgress ||
          modalProps.isTrialExtended,
        trialCancellationDisabled: !modalProps.hasActiveTrialV2,
        isCSLite: isCsLite,
      },
    });
  };

  return (
    show && (
      <MenuItem onClick={handleOpenManageTrialModal}>Manage Trial v2</MenuItem>
    )
  );
};

export default ManageTrialMenuItem;
