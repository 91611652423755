import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import {
  extendBundleTrial,
  extendSingleTrial,
} from '../../../domains/trials/api/extendTrial';
import styles from './TrialExtendModal.module.css';
import { AdmModalProps } from '../AdmModalRoot';

import { useTrialV2CustomDuration } from '../../../domains/trials/api/useTrialV2CustomPeriod';

type Props = {
  modalProps: Record<string, string>;
  onSuccess: () => void;
};

const TrialExtendModal = ({
  onCloseModal,
  props: { modalProps, onSuccess },
}: AdmModalProps<Props>) => {
  const { trialPeriod, trialPeriodElement } = useTrialV2CustomDuration({
    mode: 'extend',
  });

  const handleExtendTrial = async () => {
    if (!modalProps.bundleId && !modalProps.trialId) {
      return;
    }

    const target = modalProps.isCSLite
      ? `account_id: ${modalProps?.accountId}`
      : `site_id: ${modalProps?.siteId}`;
    const errorMessage = `Unable to extend trial for ${target}`;

    if (modalProps?.isSingleProductTrial) {
      await extendSingleTrial({
        trialId: modalProps.trialId,
        duration: trialPeriod,
        errorMessage,
        accountId: Number(modalProps?.accountId),
      });
    } else {
      await extendBundleTrial({
        bundleId: modalProps.bundleId,
        duration: trialPeriod,
        errorMessage,
        accountId: Number(modalProps?.accountId),
      });
    }

    onSuccess();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open onClose={onCloseModal}>
      <DialogTitle>Extend Trial</DialogTitle>
      <DialogContent>
        <p className={styles.copy}>
          {`You're about to extend a trial for ${
            modalProps.isCSLite ? 'account' : 'site'
          } ${
            modalProps?.name
              ? modalProps.name
              : `ID: ${
                  modalProps.isCSLite
                    ? modalProps?.accountId
                    : modalProps?.siteId
                }`
          }`}
        </p>
        {trialPeriodElement}
        <p>
          The current trial will be extended by the number of days you chose
          above.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Cancel</Button>
        <Button variant="contained" onClick={handleExtendTrial}>
          Extend trial
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrialExtendModal;
