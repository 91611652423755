import { Component } from 'react';
import { debounce } from 'lodash';
import { withRouter } from 'react-router-dom';

import {
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ClickToCopy from '../../components/ClickToCopy';
import ColoredLabel from '../../components/ColoredLabel';
import ControlledExpansionPanel from '../../components/ControlledExpansionPanel';
import DisplaySite from './DisplaySite';
import HasPermission from '../../components/HasPermission';
import OrganizationInvitationsTable from './OrganizationInvitationsTable';
import { OrganizationUsersTable } from './OrganizationUsersTable';
import componentStyles from './DisplayOrganization.module.css';
import containerStyles from './Lookup.module.css';
import { ActionsMenu } from '../../components/ActionsMenu';
import {
  MODAL_ADM_ACTIONS,
  MODAL_IGNORED_IPS,
  MODAL_INVITE_USER,
  MODAL_MODIFY_PLAN,
  MODAL_BULK_LOCK_PLAN,
  MODAL_MODIFY_PLAN_MAGPIE,
  MODAL_TRANSFER,
} from '../../components/modals/constants';
import { toUTCDateString } from '../../utils/dates';
import { undeleteOrganization } from '../Lookup/api';
import { withModalContext } from '../../contexts/ModalContext';
import { withLookupContext } from '../../contexts/LookupContext';
import { trackSegmentEvent } from '../../utils/segment';
import { getBillingV3Info } from './billingV3.functions';
import OrganizationBadges from '../../domains/organizations/components/OrganizationBadges';
import { EnableSSOForOrg } from './actions/EnableSSOForOrg';
import { DeleteOrg } from './actions/DeleteOrg/DeleteOrg';
import { AddUser } from './actions/AddUser/AddUser';
import { ProvisioningVOCWrapper } from '../ProvisioningVoCInsights';

const BASIC_PLAN = 'BASIC';
export const hasPaidSubscriptions = (site) =>
  site.products.some(
    (product) =>
      product.feature_group !== BASIC_PLAN ||
      product.effective_feature_group !== BASIC_PLAN
  );

export class DisplayOrganization extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popover: {
        open: false,
      },
      search_term: '',
    };
  }

  newAdmAction = (newAction) => {
    this.props.adm_actions.push(newAction);
  };

  handleOpenPopover = () => {
    this.setState({
      ...this.state,
      popover: {
        open: true,
      },
    });
  };

  closePopover = () => {
    this.setState({
      ...this.state,
      popover: {
        open: false,
      },
    });
  };

  handleEventTracking = () => {
    if (!this.props) return;

    const segmentData = {
      orgId: this.props.id,
      numberOfSitesInAccount: Object.values(this.props.lookupContext.sites)
        .length,
      numberOfUsersInOrg: this.props.users.length,
    };

    trackSegmentEvent('ADM Organization Actions Clicked', segmentData);
  };

  handleContextModalOpen = (modal, payload) => {
    this.closePopover();
    this.props.modalContext.openModal(modal, payload);
  };

  handlePlanSettingsModalOpen = (context, entityType) => {
    const account = this.props.account;
    const isBulkSite = context === 'bulk_site';
    const isUbpAccount = this.props.account.features.includes(
      'pp_usage_based_pricing_phase_0_variant1'
    );

    this.handleContextModalOpen(MODAL_MODIFY_PLAN, {
      onSuccess: this.props.lookupContext.lookupRequest,
      id: isBulkSite ? this.props.sites[0] : this.props.id,
      sample_rate: isBulkSite ? null : this.props.sample_rate,
      always_on_rate: isBulkSite ? null : this.props.always_on_rate,
      lock_plan: isBulkSite ? null : this.props.lock_plan,
      plan_id: isBulkSite ? null : this.props.plan_id,
      percentage_discount: isBulkSite ? null : this.props.percentage_discount,
      site_ids: isBulkSite ? [...this.props.sites] : [],
      context: context,
      pricing_table: account.pricing_table,
      isUbpAccount,
      has_always_on:
        account.features &&
        account.features.includes('billing.always_on_recordings'),
      entityType: entityType,
      name: this.props.name,
      sites: this.props.sitesLookup,
    });
  };

  handleBulkPlanLockModalOpen = () => {
    this.handleContextModalOpen(MODAL_BULK_LOCK_PLAN, {
      onSuccess: this.props.lookupContext.lookupRequest,
      site_ids: [...this.props.sites],
      sites: this.props.sitesLookup,
    });
  };

  handleUndelete = () => {
    if (
      window.confirm(
        'Are you sure you want to undelete this organization? ' +
          '\nNote: this will only restore access to account admins'
      )
    ) {
      const { id, lookupContext } = this.props;
      this.closePopover();
      undeleteOrganization({ id }).then(() => lookupContext.lookupRequest());
    }
  };

  handleFilterChange = debounce((search_term) => {
    this.setState({ search_term });
  }, 250);

  filterSite = (siteId) => {
    const site = this.props.sitesLookup[siteId];
    const { isPaidFilterChecked } = this.props;

    if (isPaidFilterChecked) {
      return hasPaidSubscriptions(site);
    } else {
      return true;
    }
  };

  componentDidUpdate(prevProps) {
    let oldProps = prevProps.match.params;
    let newProps = this.props.match.params;
    if (newProps !== oldProps) {
      this.setState({ search_term: '' });
    }
  }

  getSiteProps = (site_id) => {
    const { account, costs, magpie, organizations, sites } =
      this.props.lookupContext;
    const site = sites[site_id];
    const org = organizations[site.organization_id];

    const contextToProps = {
      ...site,
      organization: {
        ...org,
        account: { ...account },
      },
      sites: sites,
      cost: costs[site.id] || { total_price: 0.0 },
      nonprofit_site:
        account.features.indexOf('billing.nonprofit') > -1 &&
        site.plan_id !== 'basic_2000',
      magpie,
    };

    return contextToProps;
  };

  renderSubtitle = () => (
    <span className={containerStyles.cardHeaderSubtitle}>
      ID: <ClickToCopy copyIcon={true}>{this.props.id}</ClickToCopy>
    </span>
  );

  handleOnIgnoredIpClick = () => {
    this.handleContextModalOpen(MODAL_IGNORED_IPS, {
      ignored_ips: this.props.ignored_ips,
    });
  };

  handleOnActionsClick = () => {
    this.handleContextModalOpen(MODAL_ADM_ACTIONS, {
      data: this.props.adm_actions,
      related_id: this.props.id,
      related_type: 'Organization',
      newActionCallback: this.newAdmAction,
    });
  };

  render() {
    const { billingV3Converted, billingV3Unconverted, billingV3Enabled } =
      getBillingV3Info(this.props.magpie, this.props.features);

    if (this.props.deleted) {
      return (
        <ControlledExpansionPanel className={containerStyles.cardDeleted}>
          <AccordionSummary className={containerStyles.cardHeader}>
            <div className={containerStyles.cardHeaderTitles}>
              <span className={containerStyles.cardHeaderMainTitle}>
                {this.props.name}
              </span>
              {this.renderSubtitle()}
            </div>
            {!this.props.account.deleted && (
              <ActionsMenu
                isOpen={this.state.popover.open}
                openPopover={this.handleOpenPopover}
                closePopover={this.closePopover}
              >
                <MenuItem onClick={this.handleUndelete}>Undelete</MenuItem>
              </ActionsMenu>
            )}
            <ColoredLabel color="var(--red)">DELETED</ColoredLabel>
          </AccordionSummary>
        </ControlledExpansionPanel>
      );
    } else if (this.props.id) {
      return (
        <ControlledExpansionPanel
          data-hj-suppress
          className={containerStyles.card}
          defaultExpanded={
            this.props.defaultExpanded ||
            (this.props.searchBox.search_by === 'site_id' &&
              this.props.sites.includes(
                parseInt(this.props.searchBox.search_term, 10)
              )) ||
            this.props.account.organizations.length === 1 ||
            (this.props.searchBox.search_by === 'organization_id' &&
              this.props.account.organizations.includes(
                parseInt(this.props.searchBox.search_term, 10)
              ))
          }
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={containerStyles.cardHeader}
          >
            {this.props.users.some((ou) => ou.user.id === 9) ? (
              <VpnKeyIcon className={componentStyles.green} />
            ) : (
              <VpnKeyIcon className={componentStyles.grey} />
            )}
            <div className={containerStyles.cardHeaderTitles}>
              <span className={containerStyles.cardHeaderMainTitle}>
                {this.props.name}
              </span>
              {this.renderSubtitle()}
            </div>
            <ActionsMenu
              handleEventTracking={this.handleEventTracking}
              isOpen={this.state.popover.open}
              openPopover={this.handleOpenPopover}
              closePopover={this.closePopover}
            >
              <MenuItem
                onClick={() => {
                  if (billingV3Converted) {
                    return this.handleContextModalOpen(
                      MODAL_MODIFY_PLAN_MAGPIE,
                      {
                        magpie: this.props.magpie,
                        account: this.props.account,
                        isBulkEdit: true,
                        siteIds: this.props.sites,
                        sitesLookup: this.props.sitesLookup,
                      }
                    );
                  }
                  this.handlePlanSettingsModalOpen('bulk_site', 'site');
                }}
                disabled={billingV3Unconverted}
              >
                Bulk Modify Plan &amp; Rates
              </MenuItem>
              <MenuItem
                onClick={this.handleBulkPlanLockModalOpen}
                disabled={billingV3Enabled}
              >
                Bulk Lock/Unlock Plans
              </MenuItem>

              <ProvisioningVOCWrapper>
                <AddUser organizationId={this.props.id} />
              </ProvisioningVOCWrapper>
              <ProvisioningVOCWrapper showForCrossSell={false}>
                <HasPermission
                  rolesAccepted={['admin', 'insights_user_manager']}
                >
                  <MenuItem
                    onClick={() =>
                      this.handleContextModalOpen(MODAL_INVITE_USER, {
                        org_id: this.props.id,
                        org_name: this.props.name,
                        onSuccess: this.props.lookupContext.lookupRequest,
                      })
                    }
                  >
                    Invite User
                  </MenuItem>
                </HasPermission>
              </ProvisioningVOCWrapper>
              <MenuItem
                onClick={() =>
                  this.handleContextModalOpen(MODAL_TRANSFER, {
                    entityType: 'organization',
                    id_from: this.props.id,
                    onSuccess: this.props.lookupContext.lookupRequest,
                  })
                }
              >
                Transfer Organization
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handleContextModalOpen(MODAL_ADM_ACTIONS, {
                    data: this.props.adm_actions,
                    related_id: this.props.id,
                    related_type: 'Organization',
                    newActionCallback: this.newAdmAction,
                  });
                }}
              >
                Organization Activity
              </MenuItem>
              <EnableSSOForOrg
                accountId={this.props.account.id}
                orgId={this.props.id}
              />
              <ProvisioningVOCWrapper>
                <DeleteOrg
                  organizationId={this.props.id}
                  handleContextModalOpen={this.handleContextModalOpen}
                />
              </ProvisioningVOCWrapper>
            </ActionsMenu>
            <div
              className={`${componentStyles.icons} ${componentStyles['icons-org']}`}
            >
              <OrganizationBadges
                siteCount={this.props.sites.length}
                usersCount={this.props.users.length}
                actionsCount={this.props.adm_actions?.length}
                ignoredIpsCount={this.props.ignored_ips?.length}
                pendingInvitationsCount={this.props.invitations.length}
                handleOnIgnoredIpClick={this.handleOnIgnoredIpClick}
                handleOnActionsClick={this.handleOnActionsClick}
              />
            </div>
          </AccordionSummary>

          <AccordionDetails className={componentStyles.billingInfo}>
            <div>
              Percentage Discount: {this.props.percentage_discount}%<br />
              <b>
                Effective Discount: {this.props.account.percentage_discount}%
              </b>{' '}
              (Account)
            </div>
            <div>
              Created:
              <br />
              {toUTCDateString(new Date(this.props.created * 1000))}
            </div>
            {this.props.identity_provider ? (
              <div>
                SSO: Enabled
                <br />
                Login Id: {this.props.identity_provider.login_id}
              </div>
            ) : (
              <div>SSO: Disabled</div>
            )}
          </AccordionDetails>
          {this.props.sites.length > 0 && (
            <AccordionSummary className={componentStyles.sitesBlock}>
              <div>
                <span className={containerStyles.cardHeaderMainTitle}>
                  Sites ({this.props.sites.length}):
                </span>
              </div>
              <div className={componentStyles.filterField}>
                <TextField
                  label="Filter Sites by Site ID"
                  id="filter.search_term"
                  name="search_term"
                  className={componentStyles.filterTextField}
                  onChange={(event) =>
                    this.handleFilterChange(event.target.value)
                  }
                  margin="normal"
                  type="text"
                  data-hj-suppress
                  variant="standard"
                />
              </div>
              <AccordionDetails className={componentStyles.sites}>
                {this.props.sites.filter(this.filterSite).map((site_id) => {
                  if (
                    !this.state.search_term ||
                    this.state.search_term === site_id.toString()
                  ) {
                    return (
                      <DisplaySite
                        key={site_id}
                        site_id={site_id}
                        hasAccess={this.props.users.some(
                          (ou) => ou.user.id === 9
                        )}
                        {...this.getSiteProps(site_id)}
                      />
                    );
                  }
                  return null;
                })}
              </AccordionDetails>
            </AccordionSummary>
          )}
          {this.props.users.length > 0 && (
            <AccordionSummary className={componentStyles.usersBlock}>
              <div>
                <span className={containerStyles.cardHeaderMainTitle}>
                  Users ({this.props.users.length}):
                </span>
              </div>
              <OrganizationUsersTable
                users={this.props.users}
                onSuccess={this.props.lookupContext.lookupRequest}
              />
            </AccordionSummary>
          )}
          {this.props.invitations.length > 0 && (
            <AccordionSummary className={componentStyles.usersBlock}>
              <div>
                <span className={containerStyles.cardHeaderMainTitle}>
                  Invitations ({this.props.invitations.length}):
                </span>
              </div>
              <OrganizationInvitationsTable
                invitations={this.props.invitations}
                org_id={this.props.id}
                org_name={this.props.name}
                onSuccess={this.props.lookupContext.lookupRequest}
              />
            </AccordionSummary>
          )}
        </ControlledExpansionPanel>
      );
    } else {
      return null;
    }
  }
}

export default withRouter(
  withModalContext(withLookupContext(DisplayOrganization))
);
