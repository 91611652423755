import { toSentenceCase, camelKeys } from 'js-convert-case';
import { getCurrencySymbol } from '../../utils/costs';
import { getFreePlans } from '../../utils/getFreePlans';

export const getBillingV3Info = (magpie, features) => {
  const hasBillingV3Flag = features?.includes('billing_v3');
  const billingVersion = hasBillingV3Flag || magpie ? 3 : 2;
  const billingV3Converted = !!magpie;
  const billingV3Unconverted = !billingV3Converted && hasBillingV3Flag;

  return {
    billingVersion,
    billingV3Enabled: hasBillingV3Flag,
    billingV3Converted,
    billingV3Unconverted,
  };
};

// Legacy discount is calculated yearly, we need the monthly amount
const getMonthlyDiscountAmount = (name, amount) =>
  name === 'LEGACY' ? amount / 12 : amount;

export const getPrettyBillingV3Discounts = ({
  deductions,
  currency,
  discountType,
  includeZeroDiscounts,
  renderTooltip,
}) => {
  if (!deductions) {
    return [];
  }

  const symbol = getCurrencySymbol(currency);
  return deductions
    .filter(
      ({ amount, percentage }) => includeZeroDiscounts || percentage || amount
    )
    .map(
      ({
        identifier,
        amount,
        percentage,
        start_date: startDate,
        end_date: endDate,
      }) => ({
        name: toSentenceCase(identifier),
        amount:
          discountType === 'percentage'
            ? `${percentage}%`
            : `${getMonthlyDiscountAmount(identifier, amount)}${symbol}`,
        tooltip: renderTooltip?.({ startDate, endDate, amount, percentage }),
      })
    );
};

export const isBasicAllowance = (sku, productsCatalog) =>
  getFreePlans(productsCatalog).includes(sku);

export const createEntity = (entityIdentifier, entityLabel) => ({
  entityIdentifier,
  entityLabel:
    entityLabel === undefined || entityLabel === ''
      ? String(entityIdentifier)
      : entityLabel,
  entityType: 'site',
});

export const getSalesDeductionPercentage = (subscription) => {
  const compoundDiscount = camelKeys(
    subscription?.products?.find(
      (product) => product.sku === 'compound_discount'
    ),
    {
      recursive: true,
      recursiveInArray: true,
    }
  );
  return compoundDiscount?.componentsPercentageDeduction?.find(
    (component) => component.identifier === 'SALES_DEDUCTION'
  )?.percentage;
};
