import MUIDataTable from 'mui-datatables';
import LockIcon from '@mui/icons-material/Lock';
import Tooltip from '@mui/material/Tooltip';
import componentStyles from './DisplayOrganization.module.css';
import ColoredLabel from '../../components/ColoredLabel';
import { getPrettyBillingV3Discounts } from './billingV3.functions';
import { allowanceDictionary } from './billingv3.map-dictionary';
import { NO_DATA_PLACEHOLDER } from '../../utils/constants';
import SiteInfoTable from '../../domains/sites/components/SiteInfoTable';
import SiteServicePeriodsTable from '../../domains/sites/components/SiteServicePeriodsTable';

const DisplaySiteTables = ({
  cost,
  created,
  currency,
  dualSKUDiscount,
  handleOnOpenVisualizerModalClick,
  lastData,
  lockPlan,
  nonProfit,
  products,
  recValue,
  isTwoSkusActionRequired,
  billingV3Converted,
  billingV3Costs,
  billingV3Products,
  siteId,
  trialStates,
  isSiteInUBP,
  isCSLite,
  productsCatalog,
}) => {
  const siteProductTableColumns = [
    { name: 'Product Name' },
    {
      name: 'Feature Group',
      options: {
        customBodyRender: (featureGroup) => {
          const featureGroupColor =
            featureGroup.toLowerCase() === 'free'
              ? undefined
              : `var(--${featureGroup.toLowerCase()})`;

          return (
            <>
              <Tooltip
                title="Non-profit site"
                disableHoverListener={!nonProfit}
              >
                <div>
                  <ColoredLabel color={featureGroupColor}>
                    {featureGroup}
                  </ColoredLabel>
                  {nonProfit ? (
                    <ColoredLabel color="var(--green)">NONPROFIT</ColoredLabel>
                  ) : null}
                </div>
              </Tooltip>
              {lockPlan && <LockIcon className={componentStyles.lockIcons} />}
            </>
          );
        },
      },
    },
    { name: 'Base Cost' },
    { name: 'Allowances' },
    {
      name: 'Effective Feature Group',
      options: {
        customBodyRender: (featureGroup) => {
          const featureGroupName =
            featureGroup.label.toLowerCase() === 'free'
              ? undefined
              : `var(--${featureGroup.label.toLowerCase()})`;
          return (
            <ColoredLabel color={featureGroupName}>
              {featureGroup.name.toLowerCase()}
            </ColoredLabel>
          );
        },
      },
    },
    {
      name: 'Effective Allowances',
      options: {
        customBodyRender: (allowance) => (
          <div>
            {allowance.value} {allowance.label}
          </div>
        ),
      },
    },
  ];

  const trialTableColumns = [
    {
      name: 'Trial Plan',
      options: {
        customBodyRender: (trialPlan) => {
          if (!trialPlan) {
            return null;
          }
          const trialPlanColor =
            trialPlan.label.toLowerCase() === 'growth'
              ? undefined
              : `var(--${trialPlan.label.toLowerCase()})`;

          return (
            <ColoredLabel color={trialPlanColor}>{trialPlan.name}</ColoredLabel>
          );
        },
      },
    },
    {
      name: 'Trial Allowances',
      options: {
        customBodyRender: (trialAllowance) => {
          if (!trialAllowance) {
            return null;
          }

          return (
            <div>
              {trialAllowance.value} {trialAllowance.label}
            </div>
          );
        },
      },
    },
  ];

  if (trialStates.length > 0) {
    siteProductTableColumns.push(...trialTableColumns);
  }

  // TODO: watchout this one mutation outside of the table setting
  // If the customer on billing v3 and converted, add product-level discounts
  if (billingV3Converted) {
    const indexToInsert =
      siteProductTableColumns.findIndex(
        (column) => column.name === 'Base Cost'
      ) + 1;
    siteProductTableColumns.splice(indexToInsert, 0, { name: 'Discounts' });
  }

  const getBillingV3EnrichedProductsCosts = (
    legacyProduct,
    billingV3Products
  ) =>
    billingV3Products
      .map((product) => ({
        ...product,
        ...allowanceDictionary[product.sku],
      }))
      .find(
        (enrichedProduct) =>
          enrichedProduct.product?.name === legacyProduct.name &&
          Number(enrichedProduct.entity?.entity_identifier) === siteId
      );

  const getBillingV3CostsPerProduct = (product) => {
    const billingV3EnrichedCosts = getBillingV3EnrichedProductsCosts(
      product,
      billingV3Costs.purchased_product_costs
    );
    const serviceCost = billingV3EnrichedCosts?.component_costs.find(
      (component) => component.component_identifier === 'SERVICE'
    );
    return (serviceCost?.amount_without_tax ?? 0).toFixed(2);
  };

  const getBillingDiscountsPerProduct = (product) => {
    const billingV3EnrichedProduct = getBillingV3EnrichedProductsCosts(
      product,
      billingV3Products
    );
    const discounts = [
      ...getPrettyBillingV3Discounts({
        deductions: billingV3EnrichedProduct?.components_monetary_deduction,
        currency,
        discountType: 'monetary',
      }),
      ...getPrettyBillingV3Discounts({
        deductions: billingV3EnrichedProduct?.components_percentage_deduction,
        currency,
        discountType: 'percentage',
      }),
    ];

    return discounts.length
      ? discounts.map(({ name, amount }) => `${name}: ${amount}`).join('\n')
      : NO_DATA_PLACEHOLDER;
  };

  const siteProductData = products.map((product) => {
    const productName = product.name.toUpperCase();
    const isAsk = productName === 'ASK';
    const displayActionRequired = isTwoSkusActionRequired && isAsk;
    const productData = [productName, product.feature_group];

    if (billingV3Converted) {
      productData.push(getBillingV3CostsPerProduct(product));
      productData.push(getBillingDiscountsPerProduct(product));
    } else {
      productData.push(product.price);
    }

    let allowances = `${product.sessions} Pageviews`;
    if (product.cc_sessions) allowances = `${product.cc_sessions} CC Sessions`;
    if (product.cc_monthly_sessions && isSiteInUBP)
      allowances = `${product.cc_monthly_sessions} CC Monthly Sessions`;
    if (product.monthly_responses)
      allowances = `${product.monthly_responses} Monthly Responses`;
    productData.push(allowances);

    productData.push(
      displayActionRequired
        ? { name: 'Action required', label: 'business' }
        : {
            name: product.effective_feature_group,
            label: product.effective_feature_group,
          }
    );

    let effectiveAllowances = {
      label: 'Pageviews',
      value: product.effective_sessions,
    };
    if (product.effective_cc_sessions)
      effectiveAllowances = {
        label: 'CC Sessions',
        value: product.effective_cc_sessions,
      };
    if (product.effective_cc_monthly_sessions && isSiteInUBP)
      effectiveAllowances = {
        label: 'CC Monthly Sessions',
        value: product.effective_cc_monthly_sessions,
      };

    if (product.effective_monthly_responses)
      effectiveAllowances = {
        label: 'Monthly Responses',
        value: product.effective_monthly_responses,
      };
    if (displayActionRequired) {
      effectiveAllowances = {
        label: 'Responses',
        value: 'Unlimited',
      };
    }
    productData.push(effectiveAllowances);

    const updatedTrialStates = trialStates.map((trial) => {
      const productForTrial = productsCatalog?.find(
        (el) => trial.sku === el.sku
      );

      return {
        ...trial,
        ...productForTrial,
      };
    });

    const currentTrial = updatedTrialStates.find((el) =>
      // it should match isVOC but for now product returns just ASK/OBSERVE
      isAsk ? el.isAsk || el.isVoC : el.isObserve || el.isDXA
    );

    if (currentTrial) {
      let trialPlan = {
        label: currentTrial.planName,
        name: currentTrial.planName,
      };

      productData.push(trialPlan);

      const trialAllowances = {
        label: isAsk ? 'Monthly Responses' : 'CC Sessions',
        value: isAsk
          ? currentTrial.allowance.monthlyResponses
          : currentTrial.allowance.dailyCcSessions,
      };

      const trialAllowancesALP = isCSLite
        ? {
            label: isAsk ? 'Responses' : 'Sessions (Replays)',
            value: isAsk
              ? currentTrial.allowance.quotas.monthlyResponses
              : `${currentTrial.allowance.quotas.analyticsSessionQuota} (${currentTrial.allowance.quotas.recordingSessionCount})`,
          }
        : undefined;

      productData.push(isCSLite ? trialAllowancesALP : trialAllowances);
    }

    return productData;
  });

  const siteTableOptions = {
    search: false,
    sort: false,
    filter: false,
    selectableRows: 'none',
    pagination: false,
    print: false,
    download: false,
    viewColumns: false,
    responsive: 'standard',
  };

  const totalCostForSiteBillingV3 = billingV3Costs?.purchased_product_costs
    ?.filter(
      (costs) =>
        costs.entity.entity_type === 'site' &&
        Number(costs.entity.entity_identifier) === siteId
    )
    ?.reduce((a, b) => a + b.amount_without_tax, 0);

  const totalCost = (
    billingV3Converted ? totalCostForSiteBillingV3 ?? 0 : cost.total_price
  ).toFixed(2);

  return (
    <>
      <div className={componentStyles.tableWrap}>
        <SiteInfoTable
          created={created}
          lastData={lastData}
          currency={currency}
          recValue={recValue}
          totalCost={totalCost}
          deductions={cost.deductions}
          hasSkuDiscount={dualSKUDiscount}
          isBillingV3Converted={billingV3Converted}
          handleOnOpenVisualizerModalClick={handleOnOpenVisualizerModalClick}
        />
        <MUIDataTable
          data={siteProductData}
          columns={siteProductTableColumns}
          options={siteTableOptions}
        />
      </div>
      <SiteServicePeriodsTable siteId={siteId} />
    </>
  );
};

export default DisplaySiteTables;
