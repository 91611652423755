import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { debounce, isEmpty } from 'lodash';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';

import Pagination from '../../components/Pagination';
import DisplayOrganization from './DisplayOrganization';
import DisplayAccount from './DisplayAccount';
import DisplayUser from './DisplayUser';
import styles from './Lookup.module.css';
import { MODAL_USER_DETAIL } from '../../components/modals/constants';
import { flattenRecursively, includesIgnoreCase } from '../../utils/data';
import { withModalContext } from '../../contexts/ModalContext';
import { withLookupContext } from '../../contexts/LookupContext';
import DisplayAccountExecutedCommands from './DisplayAccountExecutedCommands';

const searchFilterTypes = {
  organization: 'Organization',
  siteUrl: 'Site URL',
  siteId: 'Site ID',
};

class DisplayLookup extends Component {
  state = {
    searchTerm: '',
    searchFilter: searchFilterTypes.organization,
    isOrgsExpanded: false,
    isPaidFilterChecked: false,
  };

  handleUserModalOpen = () => {
    this.props.modalContext.openModal(MODAL_USER_DETAIL, {
      user_id: this.props.user.id,
      user: this.props.user,
      email_blocklisted: this.props.user.email_blocklisted,
      is_account_owner: Boolean(this.props.user.account_id),
      updateUserEmailAction: this.props.lookupContext.updateUserEmail,
    });
  };

  handleFilterChange = (e) => this.setState({ searchFilter: e.target.value });

  handlePaidFilterChange = (e) => {
    this.setState({ isPaidFilterChecked: e.target.checked });
  };

  handleSearchTermChange = debounce((searchTerm) => {
    this.setState({ searchTerm });
  }, 250);

  getOrganisationCountLabel = () => {
    const currentPageLength =
      this.props.account && this.props.account.organizations.length;
    const lengthTotal =
      this.props.account?.organization_details?.total_orgs || 0;
    if (lengthTotal > currentPageLength) {
      return `Showing ${currentPageLength} of ${lengthTotal} Organizations`;
    }
    return `Showing ${currentPageLength} ${
      currentPageLength > 1 ? 'Organizations' : 'Organization'
    }`;
  };

  filterOrganization = (orgId) => {
    const organization = this.props.organizationsLookup[orgId];
    const searchTerm = this.state.searchTerm;
    if (this.state.searchFilter === searchFilterTypes.organization) {
      return isEmpty(searchTerm)
        ? true
        : includesIgnoreCase(
            flattenRecursively(organization).toString(),
            searchTerm
          );
    }
    if (this.state.searchFilter === searchFilterTypes.siteId) {
      return isEmpty(searchTerm)
        ? true
        : includesIgnoreCase(
            flattenRecursively(organization.sites).toString(),
            searchTerm
          );
    }
    if (this.state.searchFilter === searchFilterTypes.siteUrl) {
      const filteredSites = Object.values(
        this.props.lookupContext.sites
      ).filter((site) => includesIgnoreCase(site.url, searchTerm));
      const filteredSitesOrgIds = filteredSites.map(
        (item) => item.organization_id
      );

      return isEmpty(searchTerm)
        ? true
        : filteredSitesOrgIds.includes(organization.id);
    }
  };

  componentDidUpdate(prevProps) {
    let oldProps = prevProps.match.params;
    let newProps = this.props.match.params;
    if (newProps !== oldProps) {
      this.setState({ searchTerm: '' });
    }
  }

  setOrgProps = (orgId) => {
    const {
      account,
      costs,
      magpie,
      organizations,
      organization_users,
      searchBox,
      sites,
    } = this.props.lookupContext;
    const org = organizations[orgId];

    const contextToProps = {
      ...org,
      account: { ...account },
      cost: costs[org.id],
      searchBox: { ...searchBox },
      sitesLookup: sites,
      users: org.users.map((ou_id) => {
        return organization_users[ou_id];
      }),
      magpie,
    };

    return contextToProps;
  };

  getSitesTotalCount = () => {
    const orgs = this.props.account.organizations;
    if (orgs.length) {
      const totalSitesCount = orgs.reduce((acc, orgId) => {
        if (this.props.organizationsLookup[orgId].sites) {
          return acc + this.props.organizationsLookup[orgId].sites.length;
        }
        return acc;
      }, 0);
      return totalSitesCount > 1
        ? `${totalSitesCount} Sites`
        : `${totalSitesCount} Site`;
    }
  };

  generatePaginatedUrl = () => {
    const url_search = new URL(window.location.href);
    const page_separator = Object.keys(url_search.searchParams).length
      ? '&'
      : '?';
    return window.location.pathname + page_separator + 'page=';
  };

  render() {
    const orgs = this.props.account?.organizations || [];
    const org_details = this.props.account?.organization_details || {};
    const total_pages =
      this.props.account?.organization_details?.total_pages || 1;
    return (
      <>
        {this.props.lookupContext.isLoading ? (
          <Paper className={styles.spinnerWrapper}>
            <CircularProgress />
          </Paper>
        ) : (
          <>
            {this.props.account && (
              <DisplayAccountExecutedCommands
                hotjarAccountId={this.props.account.id}
              />
            )}
            <Paper className={styles.lookup}>
              <div className={styles.side}>
                {this.props.account && (
                  <DisplayAccount
                    {...this.props.account}
                    organizationsLookup={this.props.organizationsLookup}
                    magpie={this.props.magpie}
                  />
                )}
                {this.props.user && (
                  <DisplayUser
                    {...this.props.user}
                    openModal={this.handleUserModalOpen}
                  />
                )}
              </div>
              {this.props.account && (
                <div className={styles.main}>
                  <div className={styles.header}>
                    <div className={styles.orgs}>
                      <BusinessIcon /> {this.getOrganisationCountLabel()} |{' '}
                      {this.getSitesTotalCount()}
                    </div>
                    <div className={styles.filtersWrapper} data-testid="search">
                      <FormGroup className={styles.paidFilter}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={this.state.isPaidFilterChecked}
                              onChange={this.handlePaidFilterChange}
                            />
                          }
                          label="Paid sites only"
                        />
                      </FormGroup>
                      <div className={styles.filter}>
                        {total_pages === 1 ? (
                          <>
                            <TextField
                              className={styles.filterTextField}
                              label="Filter organizations"
                              name="searchTerm"
                              id="filter.searchTerm"
                              onChange={(event) =>
                                this.handleSearchTermChange(event.target.value)
                              }
                              type="text"
                              data-hj-suppress
                              variant="standard"
                            />
                            <FormControl>
                              <InputLabel htmlFor="filter.searchBy">
                                Search by
                              </InputLabel>
                              <Select
                                className={styles.filterSelect}
                                name="searchBy"
                                id="filter.searchBy"
                                value={this.state.searchFilter}
                                onChange={this.handleFilterChange}
                                variant="standard"
                              >
                                {Object.values(searchFilterTypes).map(
                                  (item) => (
                                    <MenuItem value={item} key={item}>
                                      {item}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </>
                        ) : (
                          <div>
                            <Pagination
                              currentPage={org_details.page_number}
                              itemsPerPage={org_details.orgs_per_page}
                              itemCount={org_details.total_orgs}
                              totalPages={org_details.total_pages}
                              nextPageUrl={
                                this.generatePaginatedUrl() +
                                (org_details.page_number + 1)
                              }
                              previousPageUrl={
                                this.generatePaginatedUrl() +
                                (org_details.page_number - 1)
                              }
                            />
                          </div>
                        )}
                        {orgs.length > 1 ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.setState((prevState) => ({
                                isOrgsExpanded: !prevState.isOrgsExpanded,
                              }))
                            }
                            disabled={false}
                            className={styles.expandButton}
                          >
                            {this.state.isOrgsExpanded
                              ? 'Collapse All'
                              : 'Expand All'}
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {this.props.account.organizations.length > 0 ? (
                    this.props.account.organizations
                      .filter(this.filterOrganization)
                      .map((org_id) => (
                        <DisplayOrganization
                          isPaidFilterChecked={this.state.isPaidFilterChecked}
                          defaultExpanded={this.state.isOrgsExpanded}
                          key={org_id}
                          org_id={org_id}
                          features={this.props.account?.features}
                          magpie={this.props.magpie}
                          {...this.setOrgProps(org_id)}
                        />
                      ))
                  ) : (
                    <Card>
                      <CardContent>
                        There are no organizations in this account. Check User
                        pop up to see what organizations the user has access to.
                      </CardContent>
                    </Card>
                  )}
                </div>
              )}
            </Paper>
          </>
        )}
      </>
    );
  }
}

export default withRouter(withLookupContext(withModalContext(DisplayLookup)));
