import React, { Component } from 'react';
import {
  Button,
  CircularProgress,
  MenuItem,
  Paper,
  Tooltip,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LinkIcon from '@mui/icons-material/Link';
import OpenInNew from '@mui/icons-material/OpenInNew';
import ClickToCopy from '../../components/ClickToCopy';
import ActionsBadge from '../../components/ActionsBadge';

import { SEPARATE_SKU_PRICING_TABLE } from '../../utils/constants.js';
import { getFeatures, undeleteUser, setNonProfit } from './api';
import { postFeatureToggle } from '../FeatureManager/api';
import { withModalContext } from '../../contexts/ModalContext';
import { withLookupContext } from '../../contexts/LookupContext';
import { ActionsMenu } from '../../components/ActionsMenu';
import ColoredLabel from '../../components/ColoredLabel';
import {
  MODAL_ADM_ACTIONS,
  MODAL_CONFIRMATION_DIALOG,
  MODAL_CREATE_SUBSCRIPTION,
  MODAL_UPDATE_CUSTOMER_DETAILS,
  MODAL_FEATURE_MANAGER,
  MODAL_MODIFY_SUBSCRIPTION,
  MODAL_TRANSFER,
  MODAL_VIEW_INVOICES,
  MODAL_UPDATE_CUSTOMER_COUNTRY,
  MODAL_UPDATE_CUSTOMER_EMAIL_RECIPIENTS,
  MODAL_MIGRATE_MANUAL,
  MODAL_PREVIEW_COSTS,
  MODAL_UPDATE_SALES_DISCOUNT,
} from '../../components/modals/constants';
import DisplayAccountDetails from './DisplayAccountDetails';
import componentStyles from './DisplayAccount.module.css';
import HasPermission from '../../components/HasPermission';
import { getBillingV3Info } from './billingV3.functions';
import { isConversionOfferEligible } from './offers.functions';
import {
  cancelSubscriptionOrderInMagpie,
  cancelSubscriptionImmediatelyAndRemoveCustomerInMagpie,
} from '../../utils/api';
import { trackSegmentEvent } from '../../utils/segment';
import { AddIDPMenuItem } from './actions/AddIDP';
import { useAccountTrialV2States } from '../../domains/trials/api/useAccountTrialV2States';
import { useCsLiteAccountTrialV2States } from '../../domains/trials/api/useCsLiteAccountTrialV2States';
import { trialStatesIncludesActiveTrials } from '../../domains/trials/utils/trialStatesIncludesActiveTrials';
import { ProvisioningVOCWrapper } from '../ProvisioningVoCInsights';
import { ProvisionCsAccount } from './actions/ProvisionCsAccount/ProvisionCsAccount';
import { fetchMagpieCustomerAccountBalanceURL } from '../../domains/magpie/api/urls';
import styles from './Lookup.module.css';
import { useIsCSLite } from '../../domains/accounts/utils/useIsCSLite';
import ManageTrialMenuItem from '../../domains/trials/components/ManageTrialMenuItem';
import { trialStatesIncludesTrialExtensionInProgress } from '../../domains/trials/utils/trialStatesIncludesTrialExtensionInProgress';
import { trialStatesIncludesExtendedTrial } from '../../domains/trials/utils/trialStatesIncludesExtendedTrial';

const MAGPIE_SUBSCRIPTION_STATUS_CANCELLED = 'CANCELLED';

class DisplayAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popover: {
        open: false,
      },
      allFeatures: null,
      isLoadingAccountBalance: false,
    };
  }

  componentDidMount() {
    this._mounted = true;
    if (this.props.features.length) {
      getFeatures().then((res) => {
        this._mounted && this.setState({ allFeatures: res.feature_flags });
      });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  newAdmAction = (newAction) => {
    this.props.adm_actions.push(newAction);
  };

  handleOpenPopover = () => {
    this.setState({
      ...this.state,
      popover: {
        open: true,
      },
    });
  };

  closePopover = () => {
    this.setState({
      ...this.state,
      popover: {
        open: false,
      },
    });
  };

  handleContextModalOpen = (modal, payload) => {
    this.closePopover();
    this.props.modalContext.openModal(modal, payload);
  };

  onSetNonprofit = (annotation) => {
    return setNonProfit(this.props.id, annotation);
  };

  handleSetNonProfitModal = (annotation) => {
    const modalWarning = (
      <span>
        This action will set <b>Account {this.props.id}</b> as nonprofit. Please
        make sure you have relevant documentation to do so.
      </span>
    );
    this.handleContextModalOpen(MODAL_CONFIRMATION_DIALOG, {
      title: 'Set Nonprofit Account',
      modalWarning: modalWarning,
      onSubmit: this.onSetNonprofit,
      onSuccess: this.props.lookupContext.lookupRequest,
    });
  };

  onManageFeature = (feature, action) => {
    return postFeatureToggle({
      changeBy: 'account_ids',
      identifiers: JSON.stringify(this.props.id),
      feature,
      action,
    });
  };

  handleFeatureManagerModal = () => {
    const modalWarning = (
      <span>
        Enable or Disable selected feature for <b>Account {this.props.id}</b>.
      </span>
    );
    this.handleContextModalOpen(MODAL_FEATURE_MANAGER, {
      title: 'Manage Features',
      modalWarning: modalWarning,
      features: this.state.allFeatures,
      onSubmit: this.onManageFeature,
      onSuccess: this.props.lookupContext.lookupRequest,
    });
  };

  handleModifySubscriptionModal = (isBillingV3 = false) => {
    this.handleContextModalOpen(MODAL_MODIFY_SUBSCRIPTION, {
      account_id: this.props.id,
      billing_cycle: this.props.billing_cycle,
      billing_date: this.props.next_billing_date,
      onSuccess: this.props.lookupContext.lookupRequest,
      isBillingV3,
    });
  };

  handleUndelete = () => {
    if (
      window.confirm(
        'Are you sure you want to undelete this account?\n' +
          'Note: account will be restored, but user will be NOT,\n' +
          'you will have to invite a new user and transfer organizations manually'
      )
    ) {
      const { id } = this.props;
      this.closePopover();
      undeleteUser({ id }).then(() => this.props.lookupContext.lookupRequest());
    }
  };

  handleEventTracking = () => {
    if (!this.props) return;

    const accountCost = this.props.magpie
      ? this.props.magpie.current_period_cost_summary.amount_without_tax
      : this.props.account_costs?.yearly_subscription_cost_without_vat.toFixed(
          2
        ) ?? 0.0;

    const segmentData = {
      accountId: this.props.id,
      numberOfSitesInAccount: Object.values(this.props.lookupContext.sites)
        .length,
      numberOfOrgsInAccount: Object.values(
        this.props.lookupContext.organizations
      ).length,
      accountCost: `${accountCost} ${this.props.currency.toUpperCase()}`,
    };

    trackSegmentEvent('ADM Account Actions Clicked', segmentData);
  };

  getFeatureName = (featureToGet) => {
    if (this.state.allFeatures) {
      let featureObject = this.state.allFeatures.find(
        (feature) => feature.name === featureToGet
      );
      return featureObject !== undefined ? featureObject.label : featureToGet;
    }

    return featureToGet;
  };

  cancelSubscriptionImmediatelyAndRemoveCustomer = () => {
    this.setState({ isLoadingAccountBalance: true });
    fetch(fetchMagpieCustomerAccountBalanceURL(this.props.id)).then(
      (response) =>
        response.json().then((response) => {
          this.setState({ isLoadingAccountBalance: false });
          const accountBalance = response['account_balance'];
          let warningText = '';
          if (accountBalance < 0) {
            warningText = `Account ${this.props.id} has a negative balance of ${accountBalance} and must first be balanced before they can be Immediately Cancelled. Please zero the balance and try again!`;
          } else {
            warningText = `Are you sure you want to cancel the subscription with immediate effect for Account ${this.props.id}? Please note that this action will cancel the subscription (if the subscription is not cancelled already), cancel the Customer account from Zuora and it will immediately revoke all the subscription services, even if the user is in the fair downgrade period.`;
          }
          this.handleContextModalOpen(MODAL_CONFIRMATION_DIALOG, {
            title: 'Cancel Subscription With Immediate Effect',
            onSubmit: () =>
              cancelSubscriptionImmediatelyAndRemoveCustomerInMagpie(
                this.props.id
              ),
            onSuccess: this.props.lookupContext.lookupRequest,
            modalWarning: warningText,
            hasConfirmationInputField: accountBalance >= 0,
            noAnnotationRequired: accountBalance < 0,
            disableConfirmation: accountBalance < 0,
            confirmationInputLabel: 'Account ID',
            confirmationInputFieldHandler: (value) => {
              return Number(value) === this.props.id;
            },
          });
        })
    );
  };

  render() {
    const {
      billingVersion,
      billingV3Converted,
      billingV3Unconverted,
      billingV3Enabled,
    } = getBillingV3Info(this.props.magpie, this.props.features);

    const isMagpieSubscriptionCancelled =
      this.props.magpie?.subscription.status ===
      MAGPIE_SUBSCRIPTION_STATUS_CANCELLED;

    const doesPaymentMethodExistAndBillingV3Enabled =
      this.props.payment.label && billingV3Enabled;

    const isModifySubscriptionButtonDisabled =
      billingV3Converted || doesPaymentMethodExistAndBillingV3Enabled;

    const isManualBillingCycle = [
      'yearly',
      'two_years',
      'three_years',
    ].includes(this.props.billing_cycle);

    return this.state.isLoadingAccountBalance ? (
      <Paper className={styles.spinnerWrapper}>
        <CircularProgress />
      </Paper>
    ) : (
      <div className={componentStyles.displayAccount}>
        <div className={componentStyles.accountHeader}>
          <h2 className={componentStyles.accountID}>
            <ClickToCopy
              overwriteText={`https://adm.hotjar.com/lookup/account_id/${this.props.id}`}
            >
              <LinkIcon className={componentStyles.urlLink} />
            </ClickToCopy>
            Account ID:{' '}
            <ClickToCopy copyIcon={true}>{this.props.id}</ClickToCopy>
            <div className={componentStyles.badge}>
              <ActionsBadge
                actionsCount={this.props.adm_actions?.length}
                handleOnClick={() => {
                  this.handleContextModalOpen(MODAL_ADM_ACTIONS, {
                    data: this.props.adm_actions,
                    related_id: this.props.id,
                    related_type: 'Account',
                    newActionCallback: this.newAdmAction,
                  });
                }}
              />
            </div>
            &nbsp;
          </h2>
          <ColoredLabel color={'var(--business)'}>
            {this.props.pricing_table.label === SEPARATE_SKU_PRICING_TABLE
              ? 'DUAL SKU PLAN'
              : 'COMBINED SKU PLAN'}
          </ColoredLabel>
          <ColoredLabel color={'var(--blue)'}>
            BILLING {billingVersion}.0
          </ColoredLabel>
          {billingV3Converted ? (
            <ColoredLabel color={'var(--business)'}>
              SUB {this.props.magpie.subscription.status}
            </ColoredLabel>
          ) : (
            ''
          )}
          {this.props.features.indexOf('prescriptive_pricing') > -1 && (
            <ColoredLabel color={'var(--green)'}>
              Prescriptive Pricing
            </ColoredLabel>
          )}
          {isConversionOfferEligible(this.props) && (
            <ColoredLabel color={'var(--green)'}>
              40% Yearly Startup Offer Eligible
            </ColoredLabel>
          )}
          {this.props.trial_state === 'trial_active' && (
            <ColoredLabel color={'var(--business)'}>On Trial</ColoredLabel>
          )}
          {this.props.trial_state === 'trial_eligible' && (
            <ColoredLabel color={'var(--business)'} bordered={true}>
              Trial Eligible
            </ColoredLabel>
          )}
          {this.props.total_cost >= 289 &&
            this.props.trial_state !== 'trial_active' && (
              <ColoredLabel color={'var(--blue)'}>Big Business</ColoredLabel>
            )}
          {this.props.type === 'agency' && (
            <ColoredLabel color={'var(--blue)'}>Agency</ColoredLabel>
          )}
          {this.props.hasActiveTrialV2 && (
            <Tooltip
              title={
                this.props.isCSLite
                  ? 'This account has an active trial v2'
                  : 'At least one site has an active trial v2'
              }
            >
              <span>
                <ColoredLabel color={'var(--business)'}>
                  Trial 2.0 active
                </ColoredLabel>
              </span>
            </Tooltip>
          )}
          {this.props.features.includes(
            'pp_usage_based_pricing_phase_0_variant1'
          ) && <ColoredLabel color={'var(--blue)'}>UBP</ColoredLabel>}
          {billingV3Converted ? (
            <p>
              <strong>NB:</strong> This account is on Billing V3, so many
              account-based functions are disabled. Please use Zuora for these
              actions.
            </p>
          ) : null}
          {billingV3Unconverted ? (
            <p>
              <strong>NB:</strong> This account is on Billing V3, but doesn't
              have account in Zuora yet or it was removed
            </p>
          ) : null}
          <ActionsMenu
            isOpen={this.state.popover.open}
            openPopover={this.handleOpenPopover}
            closePopover={this.closePopover}
            handleEventTracking={this.handleEventTracking}
            button={
              <Button
                variant="contained"
                color="primary"
                className={componentStyles.actionsButton}
              >
                Account Actions
                <ArrowDropDownIcon />
              </Button>
            }
          >
            {this.props.deleted ? (
              <MenuItem onClick={this.handleUndelete}>Undelete</MenuItem>
            ) : (
              <div>
                <Tooltip
                  title={
                    doesPaymentMethodExistAndBillingV3Enabled
                      ? 'Braintree payment details should be removed first'
                      : null
                  }
                >
                  <span>
                    <MenuItem
                      onClick={() => this.handleModifySubscriptionModal(false)}
                      disabled={isModifySubscriptionButtonDisabled}
                    >
                      Modify Subscription
                    </MenuItem>
                  </span>
                </Tooltip>

                {billingV3Converted && (
                  <MenuItem
                    onClick={() => this.handleModifySubscriptionModal(true)}
                  >
                    Extend Service
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() =>
                    this.handleContextModalOpen(MODAL_VIEW_INVOICES, {
                      account: this.props,
                    })
                  }
                >
                  View Invoices (Billing v2)
                </MenuItem>
                <MenuItem
                  onClick={() =>
                    this.handleContextModalOpen(MODAL_TRANSFER, {
                      entityType: 'account',
                      id_from: this.props.id,
                      onSuccess: this.props.lookupContext.lookupRequest,
                    })
                  }
                >
                  Change Account Owner
                </MenuItem>
                <MenuItem onClick={this.handleFeatureManagerModal}>
                  Manage Features
                </MenuItem>
                {this.props.features.indexOf('billing.nonprofit') < 0 && (
                  <HasPermission
                    rolesAccepted={['admin', 'billing_management_2']}
                  >
                    <MenuItem
                      data-testid="openSetNonProfitButton"
                      onClick={this.handleSetNonProfitModal}
                      disabled={billingV3Converted}
                    >
                      Make Nonprofit Account
                    </MenuItem>
                  </HasPermission>
                )}
                <MenuItem
                  onClick={() => {
                    this.handleContextModalOpen(MODAL_ADM_ACTIONS, {
                      data: this.props.adm_actions,
                      related_id: this.props.id,
                      related_type: 'Account',
                      newActionCallback: this.newAdmAction,
                    });
                  }}
                >
                  Account Activity
                </MenuItem>
                {billingVersion === 3 && !billingV3Converted && (
                  <MenuItem
                    onClick={() => {
                      this.handleContextModalOpen(MODAL_CREATE_SUBSCRIPTION, {
                        accountId: this.props.id,
                      });
                    }}
                  >
                    Create Manual Subscription
                  </MenuItem>
                )}
                {billingVersion === 2 && isManualBillingCycle && (
                  <MenuItem
                    onClick={() => {
                      this.handleContextModalOpen(MODAL_MIGRATE_MANUAL, {
                        accountId: this.props.id,
                      });
                    }}
                  >
                    Migrate Manual Customer to B3.0
                  </MenuItem>
                )}
                {billingV3Unconverted && (
                  <MenuItem
                    onClick={() => {
                      this.handleContextModalOpen(
                        MODAL_UPDATE_CUSTOMER_COUNTRY,
                        {
                          accountId: this.props.id,
                        }
                      );
                    }}
                  >
                    Update Country
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    this.handleContextModalOpen(
                      MODAL_UPDATE_CUSTOMER_EMAIL_RECIPIENTS,
                      {
                        accountId: this.props.id,
                      }
                    );
                  }}
                >
                  Update Email Recipients
                </MenuItem>
                <AddIDPMenuItem accountId={this.props.id} />
                {billingV3Converted ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        this.handleContextModalOpen(MODAL_PREVIEW_COSTS);
                      }}
                    >
                      Preview Costs
                    </MenuItem>
                    {this.props.magpie?.payment_method?.payment_method_type ===
                      'EXTERNAL_PAYMENT_METHOD' && (
                      <MenuItem
                        onClick={() => {
                          this.handleContextModalOpen(
                            MODAL_UPDATE_SALES_DISCOUNT
                          );
                        }}
                      >
                        Update Sales Discount
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        this.handleContextModalOpen(
                          MODAL_UPDATE_CUSTOMER_DETAILS,
                          {
                            accountId: this.props.id,
                          }
                        );
                      }}
                    >
                      Update Customer Details
                    </MenuItem>
                    <MenuItem
                      disabled={isMagpieSubscriptionCancelled}
                      onClick={() => {
                        if (isMagpieSubscriptionCancelled) return;
                        this.handleContextModalOpen(MODAL_CONFIRMATION_DIALOG, {
                          title: 'Cancel Automatic Renewal',
                          onSubmit: () => {
                            return cancelSubscriptionOrderInMagpie(
                              this.props.id
                            );
                          },
                          onSuccess: this.props.lookupContext.lookupRequest,
                          // modalWarning: `Are you sure you want to Cancel the Subscription Order for Account ${this.props.id}?`,
                          modalWarning: `Are you sure you want to Cancel the Automatic renewal for Account ${this.props.id}? (This action is NOT YET recorded)`,
                        });
                      }}
                    >
                      Cancel Automatic Renewal
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        this.cancelSubscriptionImmediatelyAndRemoveCustomer()
                      }
                    >
                      Cancel Subscription With Immediate Effect
                    </MenuItem>
                    <MenuItem
                      href={this.props.magpie?.external_customer?.external_url}
                      target="_blank"
                      component="a"
                    >
                      Go to Zuora <OpenInNew style={{ fontSize: '1em' }} />
                    </MenuItem>
                  </>
                ) : null}
                <ProvisioningVOCWrapper>
                  <ProvisionCsAccount accountId={this.props.id} />
                </ProvisioningVOCWrapper>
                <ManageTrialMenuItem
                  show={this.props.isCSLite}
                  isCsLite={this.props.isCSLite}
                  modalContext={this.props.modalContext}
                  modalProps={{
                    name: this.props.company,
                    siteId: null,
                    accountId: this.props.id,
                    hasActiveTrialV2: this.props.hasActiveTrialV2,
                    trialStates: this.props.trialStates,
                    isTrialExtensionInProgress:
                      this.props.isTrialExtensionInProgress,
                    isTrialExtended: this.props.isTrialExtended,
                    productCatalogVersion: this.props.productCatalogVersion,
                    onCloseModalPopover: this.closePopover,
                  }}
                />
              </div>
            )}
          </ActionsMenu>
        </div>
        <DisplayAccountDetails
          getFeatureName={this.getFeatureName}
          {...this.props}
        />
      </div>
    );
  }
}

const withHooks = (Component) => {
  return function WrappedComponent(props) {
    const account = props;
    const isCSLite = useIsCSLite();

    const { data: accountSitesTrialStates } = useAccountTrialV2States(
      isCSLite ? undefined : account
    );
    const { data: csLiteAccountTrialStates } = useCsLiteAccountTrialV2States(
      isCSLite ? account : undefined
    );

    const accountTrialStates = isCSLite
      ? csLiteAccountTrialStates
      : accountSitesTrialStates;

    const hasActiveTrialV2 =
      trialStatesIncludesActiveTrials(accountTrialStates);
    const isTrialExtensionInProgress =
      trialStatesIncludesTrialExtensionInProgress(accountTrialStates);
    const isTrialExtended =
      trialStatesIncludesExtendedTrial(accountTrialStates);

    return (
      <Component
        {...props}
        hasActiveTrialV2={hasActiveTrialV2}
        isCSLite={isCSLite}
        isTrialExtensionInProgress={isTrialExtensionInProgress}
        isTrialExtended={isTrialExtended}
        trialStates={accountTrialStates}
        productCatalogVersion={props.pricing_table?.product_catalog_version}
      />
    );
  };
};

export default withLookupContext(withModalContext(withHooks(DisplayAccount)));
